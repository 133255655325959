@mixin messageBlock() {
  display: flex;
  align-items: center;
  color: $color-red-90;
  background: $color-red-20;
  padding-left: 1.5em;

  svg {
    height: 1.2em;
    width: 1.2em;
    fill: $color-red-90;
    margin-right: $space-sm;
  }
}

.research-go-error {
  @include messageBlock();
  padding: 1.5em;
}

.research-go-warning {
  @extend .research-go-error;
  color: $color-orange-90;
  background-color: $color-orange-20;

  svg {
    fill: $color-orange-90;
  }
}

.research-go-info-message {
  @extend .research-go-error;
  color: $color-blue-90;
  background-color: $color-light-blue-10;

  svg {
    fill: $color-blue-90;
    flex-shrink: 0;
    align-self: flex-start;
  }
}

.research-go-success-message {
  @extend .research-go-error;
  color: $color-green-90;
  background-color: $color-green-10;

  svg {
    fill: $color-green-90;
    flex-shrink: 0;
    align-self: flex-start;

    polyline {
      stroke: white;
      stroke-width: 3;
    }
  }
}
