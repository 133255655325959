/**
 * Do not edit directly
 * Generated on Thu, 13 Feb 2020 16:32:14 GMT
 */

$color-base-blue: #008cff !default;
$color-base-black: #1e1e1e !default;
$color-base-red: #fd5000 !default;
$color-base-orange: #f9a21b !default;
$color-base-green: #43b02a !default;
$color-base-light-blue: #14b1e5 !default;
$color-base-dark-blue: #071d49 !default;
$color-base-pink: #c22d8c !default;
$color-base-white: #ffffff !default;
$color-base-mayo: #0046ad !default;
$color-base-grey: #6d6c6d !default;
$color-blue-10: #cce8ff !default;
$color-blue-20: #99d1ff !default;
$color-blue-50: #008cff !default;
$color-blue-70: #0070cc !default;
$color-blue-90: #005499 !default;
$color-black: #1e1e1e !default;
$color-white: #ffffff !default;
$color-red-10: #ffdccc !default;
$color-red-20: #feb999 !default;
$color-red-50: #fd5000 !default;
$color-red-70: #ca4000 !default;
$color-red-90: #983000 !default;
$color-light-blue-10: #d0effa !default;
$color-light-blue-20: #a1e0f5 !default;
$color-light-blue-50: #14b1e5 !default;
$color-light-blue-70: #108eb7 !default;
$color-light-blue-90: #0c6a89 !default;
$color-dark-blue-10: #cdd2db !default;
$color-dark-blue-20: #9ca5b6 !default;
$color-dark-blue-50: #071d49 !default;
$color-dark-blue-70: #06173a !default;
$color-dark-blue-90: #04112c !default;
$color-pink-10: #f3d5e8 !default;
$color-pink-20: #e7abd1 !default;
$color-pink-50: #c22d8c !default;
$color-pink-70: #9b2470 !default;
$color-pink-90: #741b54 !default;
$color-orange-10: #feecd1 !default;
$color-orange-20: #fddaa4 !default;
$color-orange-50: #f9a21b !default;
$color-orange-70: #c78216 !default;
$color-orange-90: #956110 !default;
$color-green-10: #d9efd4 !default;
$color-green-20: #b4dfaa !default;
$color-green-50: #43b02a !default;
$color-green-70: #368d22 !default;
$color-green-90: #286a19 !default;
$color-grey-5: #f9f9f9 !default;
$color-grey-10: #e2e2e2 !default;
$color-grey-20: #c5c4c5 !default;
$color-grey-50: #6d6c6d !default;
$color-grey-70: #575657 !default;
$color-grey-90: #414141 !default;
$color-mayo-5: #f4f8ff !default;
$color-mayo-10: #e5ebf6 !default;
$color-mayo-20: #ccd8ed !default;
$color-mayo-40: #99b1db !default;
$color-mayo-50: #0046ad !default;
$color-mayo-90: #071d49 !default;
$color-font-base: #1e1e1e !default;
$duration-base: 246ms;
$timing-ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.276);
$timing-ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$timing-ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
$timing-ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$size-xs: 0.25rem !default;
$size-sm: 0.5rem !default;
$size-m: 0.75rem !default;
$size-l: 1.25rem !default;
$size-xl: 2rem !default;
$size-xxl: 3.25rem !default;
$size-xxxl: 5.25rem !default;
$size-base: 0.75rem !default;