.bouncy-loader {
  max-width: 100px;
  text-align: center;
  color: #555;
}

$delays: 0 125ms 250ms 0.375s;
@for $i from 1 through 4 {
  .circle#{$i} {
    animation: bounce linear 2.2s infinite forwards;
    animation-delay: nth($delays, $i);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  20%,
  50% {
    transform: translateY(9px);
  }
  80% {
    transform: translateY(2px);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  }
}
