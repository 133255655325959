%clippy-img {
  object-fit: cover;
  border-radius: 50%;
  width: 5em;
  fill: white;

  @supports (clip-path: circle(40%)) {
    clip-path: circle(40%);
  }
}
