@import "tokens";

$space-inset-default: $size-base;
$space-inset-xs: $size-xs;
$space-inset-sm: $size-sm;
$space-inset-m: $size-m;
$space-inset-l: $size-l;
$space-inset-xl: $size-xl;
$space-inset-xxl: $size-xxl;
$space-inset-xxxl: $size-xxxl;

$space-stack-default: 0 0 $size-base 0;
$space-stack-xs: 0 0 $size-xs 0;
$space-stack-sm: 0 0 $size-sm 0;
$space-stack-m: 0 0 $size-m 0;
$space-stack-l: 0 0 $size-l 0;
$space-stack-xl: 0 0 $size-xl 0;
$space-stack-xxl: 0 0 $size-xxl 0;
$space-stack-xxxl: 0 0 $size-xxxl 0;

$space-default: $size-base;
$space-xs: $size-xs;
$space-sm: $size-sm;
$space-m: $size-m;
$space-l: $size-l;
$space-xl: $size-xl;
$space-xxl: $size-xxl;
$space-xxxl: $size-xxxl;
