@import "./icon-action";
@import "./tokens";

$transition: all 146ms ease;

///
/// This function compares the contrast ratio of the color passed in to #fff and #222 and returns the one with the highest contrast.
/// @example
/// .my-class {
///   color: pickFontColor(blue);
/// }
/// @param {color} $color - The base color to use in the contrast comparison
/// @return {color} $white or $dark, whichever has the highest contrast ratio
/// @group Craft Button
///
@function pickFontColor($color) {
  @return pick_best_color($color, ($color-white, $color-black));
}

@mixin checkWcagColorCompliance($c1, $c2) {
  @if (isWcagStandardAACompliant($c1, $c2) == false) {
    @warn ("Colors not AA compliant with WCAG standards: " + $c1 + " :: " + $c2);
  }

  @if (isColorCompliant($c1, $c2) == false) {
    @warn ("Colors not brightness/difference compliant with WCAG standards: " + $c1 + " :: " + $c2);
  }
}

/// @access private
@mixin btn {
  padding: 0.5em 0.75em;
  line-height: 1;
  border: 1px solid transparent;
  background-color: transparent;
  transition: $transition, color 0ms ease, border 352ms ease;
  font-size: 1em;
  border-radius: $radius;
  color: #333;
  min-width: min-content;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  white-space: nowrap;
  outline: none;
  user-select: none;

  &:enabled:hover {
    cursor: pointer;
  }
}

@mixin pill {
  border-radius: 100px;
}

@mixin outlined-button($color) {
  border-color: $color;
  border-radius: $radius;
  color: $color;
  fill: $color;
  position: relative;
  overflow: hidden;

  &::before {
    background: rgba($color, 0.4);
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }

  &:enabled:focus:not(:active)::before {
    animation: ripple 0.7s ease-out;
  }

  &:disabled {
    background-color: $color-white;
    border-color: $color-grey-20;
    color: $color-grey-20;
    @include shadow(0);
  }
}

@mixin stateful-button($color) {
  display: none;

  &:disabled + label {
    color: $color-grey-20;
    fill: $color-grey-20;
  }

  + label {
    @include btn();
    @include outlined-button($color);
    padding-left: $space-m;
    padding-right: $space-m;
    border-bottom-width: 1px;
    border-radius: 0;
    cursor: pointer;
    position: relative;
    font-weight: 400;
  }

  + label::after {
    height: 0;
    width: 0;
    position: absolute;
    content: "";
    top: 100%;
    left: calc(50% - #{$space-sm});
  }

  &:checked + label {
    background: $color;
    color: rgba(pickFontColor($color), 0.9);
  }

  &:first-of-type + label {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
  }

  &:not(:first-of-type) + label {
  }

  &:last-of-type + label {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }

  @for $i from 2 through 50 {
    &:nth-child(#{$i}) + label {
      left: -#{floor($i / 2)}px;
    }
  }
}

///
/// Mixin for building your own craft filled buttons.
///
/// @param {color} $color - Color of the button background
/// @example
/// .my-custom-button-class {
///    @include filled-button(#3a32ab);
/// }
///
/// <button class="my-custom-button-class">Click Me</button>
/// @group Craft Button
/// @requires {function} pickFontColor - automatically chooses the best color to use for font based on contrast
/// @requires {mixin} shadow - used to create button depth
///
@mixin filled-button($color) {
  // @include checkWcagColorCompliance($color, pickFontColor($color));

  border-radius: $radius;
  background-color: $color;
  color: pickFontColor($color);
  fill: pickFontColor($color);
  border-color: $color;
  position: relative;
  overflow: hidden;

  &::before {
    background: rgba(pickFontColor($color), 0.4);
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
  }

  &:enabled:hover,
  &:enabled:focus {
    background: mix(black, $color, 10%);
  }

  &:focus:not(:active)::before {
    animation: ripple 0.7s ease-out;
  }

  &:disabled {
    background-color: $color-white;
    border-color: $color-grey-20;
    color: $color-grey-20;
    @include shadow(0);
  }
}

///
/// Mixin for building your own craft primary buttons.
///
/// @param {color} $color - Color of the button background
/// @example
/// .my-custom-button-class {
///    @include primary-button(#3a32ab);
/// }
///
/// <button class="my-custom-button-class">Click Me</button>
/// @group Craft Button
/// @requires {function} pickFontColor - automatically chooses the best color to use for font based on contrast
/// @requires {mixin} shadow - used to create button depth
///
@mixin primary-button($color) {
  @include filled-button($color);
}

///
/// Mixin for building your own craft flat buttons.
///
/// @param {color} $color - Color of the button underline
/// @example
/// .my-custom-button-class {
///    @include flat-button(#3a32ab);
/// }
///
/// <button class="my-custom-button-class">Click Me</button>
/// @group Craft Button
///
@mixin minimalist-button($border-color, $text-color: $border-color) {
  padding: 0.5em 0.25em;
  border: 0;
  border-radius: $radius $radius 0 0;
  color: $text-color;
  fill: $text-color;
  background: transparent;

  &:enabled:hover,
  &:enabled:focus {
    cursor: pointer;

    &:after {
      width: 100%;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    bottom: 0;
    border-bottom: 2px solid $border-color;
    margin: auto;
    transition: $transition;
  }

  &:disabled {
    color: $color-grey-20;
  }
}

@mixin overlay-button($color) {
  @include outlined-button($color);
  border: 0;
  background: rgba($color-black, 0.04);

  &:enabled:hover,
  &:enabled:focus {
    background: rgba($color-black, 0.08);
  }
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(50, 50);
  }
}
