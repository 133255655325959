@import "../../../node_modules/@element/ui/modules/core";
@import "../../../node_modules/@element/ui/modules/buttons";
@import "../../../node_modules/@element/ui/modules/motion";

@import "../../../node_modules/research-go-shared/src/styles/left-accent";
@import "../../../node_modules/research-go-shared/src/styles/popover";
@import "../../../node_modules/research-go-shared/src/styles/overlay";
@import "../../../node_modules/research-go-shared/src/styles/list";

%circle-pic {
  object-fit: cover;
  border-radius: 50%;
  fill: white;

  @supports (clip-path: circle(40%)) {
    clip-path: circle(40%);
  }
}

#review-feedback {
  display: flex;
  justify-content: center;
}

.feedback-list {
  padding: 0;
  margin: 0;
  @include staggerEntry(li, 0.1s);
  li {
    @include fadeInFromLeft();
  }
}
.feedback-list + .discussion {
  @extend %overlay;
  @include fadeInFromRight();
  width: calc(95% - 3.5rem);
  @include shadow(1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  form {
    display: flex;
    flex-direction: column;

    textarea {
      align-self: stretch;
      margin: $space-stack-m;
    }

    button[type="submit"] {
      align-self: flex-start;
    }
  }

  ol {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: $space-stack-xl;
    @include staggerEntry(li, 0.1s, 0.15s);

    li {
      display: grid;
      grid-template-columns: 3em 1fr auto;
      grid-template-rows: auto auto;
      grid-column-gap: $space-sm;
      @include fadeInFromRight();
      align-items: center;
      border-bottom: 1px solid $color-grey-10;
      margin: $space-stack-m;
      padding-bottom: $space-m;

      &:last-of-type {
        border-bottom: none;
      }

      img {
        height: 3em;
        @extend %circle-pic;
        grid-row-start: 2;
        grid-row-end: 3;
        align-self: center;
      }

      .note {
        grid-column-start: 1;
        grid-column-end: 4;
        padding: 0.5em 0;
        text-align: justify;
        font-weight: 300;
      }

      .name,
      .timestamp {
        font-size: 0.9em;
        color: $color-grey-50;
      }
    }
  }
}

.feedback-item {
  .name,
  .timestamp {
    font-weight: 300;
  }

  .actions {
    font-size: 0.8em;
    grid-column-start: 2;
    display: flex;
    justify-content: flex-end;

    button {
      justify-self: end;
      margin-left: $space-sm;
    }
  }

  @include media("<=450px") {
    .card-body > * {
      margin: $space-stack-sm;
    }

    .feedback-content {
      margin: $space-stack-m;
    }

    div.timestamp {
      font-size: 0.8em;
      align-self: start;
      grid-row-start: 4;
      grid-row-end: 5;
    }
  }

  @include media(">450px") {
    .card-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .feedback-content {
      align-self: stretch;
      flex-grow: 1;
    }
    .timestamp {
      grid-area: timestamp;
      margin: $space-m 0;
    }
    .actions {
      align-self: flex-end;
    }
    .feedback-content,
    .actions {
      // display: flex;
      align-items: baseline;
    }

    .contact {
      justify-content: center;
    }

    div.name,
    div.timestamp {
      justify-self: right;
    }
    div.timestamp {
      font-size: 0.8em;
      // grid-row-start: 4;
      // grid-row-end: 5;
    }

    div.name {
      font-size: 0.9em;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    img {
      grid-column-start: 1;
      grid-column-end: 2;
      justify-self: center;
    }
  }

  display: grid;

  .feedback-content {
    color: $color-grey-90;
    font-weight: 300;
  }
}
