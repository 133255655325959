@import "tokens";
@import "space";

@mixin formFieldState($s) {
  @if ($s == "hover") {
    border-color: $color-grey-50;
  }

  @if ($s == "focus") {
    border-color: $color-blue-70;
  }

  @if ($s == "error") {
    border-color: $color-red-50;
    box-shadow: 0 0 0 1px $color-red-50;
    outline-color: $color-red-50;
  }

  @if ($s == "warn") {
    border-color: $color-orange-50;
    box-shadow: 0 0 0 1px $color-orange-50;
    outline-color: $color-orange-50;
  }

  @if ($s == "success") {
    border-color: $color-green-50;
    box-shadow: 0 0 0 1px $color-green-50;
    outline-color: $color-green-50;
  }

  @if ($s == "disabled") {
    background-color: $color-grey-5;

    &:hover {
      border-color: $color-grey-20;
    }
  }
}

$pd: 0.75rem;

@mixin formField {
  padding: $space-inset-sm;
  padding-top: $size-m;
  padding-bottom: $size-m;
  background-color: $color-white;
  border: 1px solid $color-grey-20;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  transition: border-color 246ms ease;
  border-radius: 4px;
  min-width: 180px;

  &:hover {
    @include formFieldState("hover");
  }

  &:focus {
    @include formFieldState("focus");
  }

  &.error {
    @include formFieldState("error");
  }

  &.warn {
    @include formFieldState("warn");
  }

  &.success {
    @include formFieldState("success");
  }

  &:disabled {
    @include formFieldState("disabled");
  }
}

@mixin contextMessageState($s) {
  @if $s == null {
    color: $color-grey-90;
    fill: $color-grey-90;
  }

  @if $s == "error" {
    color: $color-red-90;
    fill: $color-red-70;
  }

  @if $s == "warn" {
    color: $color-orange-90;
    fill: $color-orange-70;
  }

  @if $s == "success" {
    color: $color-green-90;
    fill: $color-green-70;
  }
}

@mixin contextMessage($s: null) {
  font-weight: 400;
  font-size: 14px;
  padding: $space-xs 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  line-height: 1;
  @include contextMessageState($s);
}
