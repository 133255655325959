/// Computes a top-shadow for a depth effect.
/// @access private
/// @group Shadows
/// @param {Number} $depth - depth level
/// @return {List}
@function top-shadow($depth) {
  $primary-offset: nth(3 2 1 0 0 0, $depth) * 1px;
  $blur: nth(3 2 1 0 0 0, $depth) * 4px;
  $color: rgba(black, nth(.1 .07 .05 0 0 0, $depth));

  @return 0 $primary-offset $blur $color;
}

/// Computes a bottom-shadow for a card effect.
/// @access private
/// @group Shadows
/// @param {Number} $depth - depth level
/// @return {List}
@function bottom-shadow($depth) {
  $primary-offset: nth(3 5 8 13 21 34, $depth) * 1px;
  $blur: nth(3 5 8 13 21 34, $depth) * 1px;
  $color: rgba(black, nth(.3 .3 .4 .5 .6 .7, $depth));

  @return 0 $primary-offset #{$primary-offset * 2} #{-$blur} $color;
}

/// Gives an element a depth effect.
/// @access public
/// @group Shadows
/// @param {Number} $depth - depth level (between 1 and 5)
/// @link http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality Google Design
/// @requires {function} top-shadow
/// @requires {function} bottom-shadow
@mixin shadow($depth: 1) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 6 {
    @warn "Invalid $depth `#{$depth}` for mixin `shadow`.";
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);
  }
}
