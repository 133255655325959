@import "~@element/ui/modules/core";
@import "left-accent";
@import "popover";

%circle-pic {
  object-fit: cover;
  border-radius: 50%;
  fill: white;

  @supports (clip-path: circle(40%)) {
    clip-path: circle(40%);
  }
}

.card {
  padding: 1em;
  background: $color-grey-5;
  @include leftAccent($color-grey-20);
  color: $color-grey-20;
  display: flex;
  flex-direction: column;
  position: relative;
}

ul.user-cards {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user-cards .card {
  margin: $space-stack-xl;

  @include media("<=450px") {
    .card-body {
      border-top: 1px solid $color-grey-10;
      padding-top: $space-sm;
      margin-top: $space-sm;
    }
    header {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: $space-sm;

      div.name {
        font-size: 1.1em;
        justify-self: start;
        align-self: end;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      img {
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
  }
  header {
    div.name {
      color: $color-dark-blue-50;
    }

    img {
      width: 4em;
      height: 4em;
      box-sizing: border-box;
      &:not(.anon) {
        @extend %circle-pic;
      }

      &.anon {
        padding: 0.5em;
      }
    }
  }
  .contact {
    font-size: 0.8em;
    display: flex;
    white-space: nowrap;
    align-items: center;
    flex-wrap: wrap;

    > *:first-child {
      margin-right: 1em;
    }

    a,
    label {
      position: relative;
      display: flex;
      align-items: center;
      color: $color-grey-50;
      padding: 0;
      margin: 0;
    }

    svg {
      height: 16px;
      fill: $color-grey-50;
    }

    input {
      display: none;
    }

    .phone-popover {
      display: none;
      position: absolute;
    }

    input:checked + .phone-popover {
      display: block;
      @include popover;
      animation: slide-togglable 250ms forwards;
      top: calc(100% + 10px);
      right: -10px;
    }
  }

  @include media(">450px") {
    display: flex;
    flex-direction: row;
    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20vw;
      min-width: 20vw;
      text-align: center;
      > * {
        margin: $space-stack-xs;
      }
    }

    .card-body {
      border-left: 1px solid $color-grey-10;
      margin-left: 1em;
      padding-left: 1em;
      flex-grow: 1;
    }
  }
}
