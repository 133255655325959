@import "./core/tokens";

@keyframes fade-in-from-left {
  from {
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@mixin fadeInFromLeft($duration: 246ms, $delay: 0s, $easing: ease-out) {
  opacity: 0;
  animation: fade-in-from-left $duration $delay $easing forwards;
}

@keyframes fade-in-from-right {
  from {
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@mixin fadeInFromRight($duration: 246ms, $delay: 0s, $easing: ease-out) {
  opacity: 0;
  animation: fade-in-from-right $duration $delay $easing forwards;
}

@keyframes fade-in-from-top {
  from {
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@mixin fadeInFromTop($duration: 246ms, $delay: 0s, $easing: ease-out) {
  opacity: 0;
  animation: fade-in-from-top $duration $delay $easing forwards;
}

@keyframes grow-in {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@mixin growIn($duration: 246ms, $delay: 0s, $easing: ease-out) {
  opacity: 0;
  animation: grow-in $duration $delay $easing forwards;
}

@keyframes fade-in-from-bottom {
  from {
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@mixin fadeInFromBottom($duration: 246ms, $delay: 0s, $easing: ease-out) {
  opacity: 0;
  animation: fade-in-from-bottom $duration $delay $easing forwards;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@mixin fadeIn($duration: 346ms, $delay: 0s, $easing: ease-out) {
  opacity: 0;
  animation: fade-in $duration $delay $easing forwards;
}
