@keyframes menu-popover {
  0% {
    max-height: 0;
    opacity: 0;
  }

  100% {
    max-height: 200px;
    opacity: 1;
  }
}

@keyframes slide-togglable {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  60% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@mixin slide-togglable {
  opacity: 0;
}

@mixin slide-togglable-trigger {
  @include togglable-trigger;

  & + input:not(:checked) + div {
    max-height: 0;
  }

  & + input:checked + div {
    animation: menu-popover 400ms ease-in-out forwards;
  }
}

@mixin popover {
  @include shadow(4);
  background-color: white;
  z-index: 100;
  padding: 1em;
  transform: scale(0);
  transform-origin: right top;
  border-radius: 4px;
  border: 1px solid $color-grey-10;

  > div {
    padding: 0.5em;
  }

  &::before {
    content: " ";
    pointer-events: none;
    border: 10px solid $color-grey-10;
    position: absolute;
    top: -20px;
    right: 5px;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
  }
}

@mixin togglable-trigger {
  color: $color-blue-90;
  display: flex;
  align-items: center;

  & + input {
    display: none;
  }

  > svg {
    height: 0.8em;
    width: 0.8em;
    fill: $color-blue-90;
    margin-right: $space-xs;
  }
}

@mixin popover-trigger {
  @include togglable-trigger;

  & + input:not(:checked) + div {
    max-height: 0;
  }

  & + input:checked + div {
    animation: slide-togglable 400ms ease-in-out forwards;
  }
}
