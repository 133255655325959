@import "~@element/ui/modules/motion";
@import "../../../shared/src/styles/messages";

@mixin announcement-list {
  padding: 0;
  margin: 0;
  .grid-msg-override {
    display: grid;
    svg {
      margin: 0;
    }
  }
  li.announcement {
    margin: $space-stack-m;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    grid-row-gap: 0.5em;
    grid-column-gap: 0.5em;
    @include fadeInFromLeft();

    header {
      grid-row-start: 1;
      font-size: 1.1em;
      grid-row-end: 2;
    }

    &.Warning {
      @extend .research-go-warning;
      @extend .grid-msg-override;
    }
    &.Information {
      @extend .research-go-info-message;
      background: white;
      border: 1px solid $color-blue-10;
      @extend .grid-msg-override;
    }
    &.Error {
      @extend .research-go-error;
      @extend .grid-msg-override;
    }

    .announcement-body {
      flex-grow: 1;
      grid-column-start: 1;
      grid-column-end: 3;
      border-top: 1px solid;
      padding-top: 0.5em;
      font-size: 0.9em;

      ul {
        padding-left: 1.25em;
      }

      img,
      svg {
        max-width: 100%;
      }
    }

    > svg {
      grid-row-start: 1;
      grid-row-end: 2;
    }

    button.dismiss {
      background: none;
      border: none;
      padding: 0.25em;

      svg {
        margin: 0;
      }
    }

    .dismiss {
      grid-row-start: 1;
      grid-row-end: 3;
      align-self: stretch;
    }
  }
}
