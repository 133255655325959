@import "~@element/ui/modules/core";
@import "~@element/ui/modules/motion";
@import "../../../shared/src/styles/list";

#ticket-view {
  @include fadeIn();
  h1 {
    padding-top: 1.5em;
  }
  ul {
    list-style: none;
  }
  li {
    padding-bottom: 1em;
    border-bottom: 1px solid $color-grey-10;
    &:last-child {
      border-bottom: none;
    }
    div {
      font-size: 0.8em;
      color: $color-grey-50;
      &:last-child {
        margin-top: 0.5em;
        font-size: 1em;
      }
    }
  }
  h3 {
    font-weight: 500;
    font-size: 1em;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    button {
      margin-left: 1em;
      background: transparent;
      border: none;
      padding: 0.5em;
      svg {
        margin: 0;
        height: 1em;
      }
    }
  }
}
