@import "~@element/ui/modules/core";
@import "~@element/ui/modules/motion";
@import "../../../shared/src/styles/list";

#research-go-service {
  h4 {
    margin: $space-stack-l;
  }

  ul {
    @include listWrapper();
  }
  #service-form-list {
    ul {
      margin: 1em 0;
    }
    li {
      @include listItem();

      a {
        color: $color-black;
        text-decoration: none;
      }
    }
  }

  form#submit-to-sn {
    label {
      display: flex;
      font-size: 0.9em;
      border-bottom: 1px solid $color-grey-10;

      flex-direction: column;
      align-items: stretch;
      margin: $space-stack-l;
      padding-bottom: $space-l;
    }

    li:last-of-type label {
      border: none;
      padding-bottom: 0;
    }

    button[type="submit"] {
      margin: $space-stack-m;
    }
  }

  .research-go-error,
  .research-go-info-message {
    @include fadeIn();

    button {
      background: transparent;
      border: none;
      padding: 0.5em;
      svg {
        margin: 0;
      }
    }
  }
}
