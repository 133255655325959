#research-go-undo {
  position: fixed;
  bottom: 120px;
  right: 20px;
  padding: 1em;
  background-color: $color-blue-50;
  color: white;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 4px;
  @include fadeInFromBottom();

  @include media("<500px") {
    width: calc(100vw - 40px);
  }

  button {
    color: white;
    background-color: $color-blue-50;
    border: none;
    padding-left: $space-sm;
    margin-left: $space-sm;
  }
}
