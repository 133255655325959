@import "../core/forms";

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  @include formField;
  background: transparent;
  height: 100%;
  width: 100%;
  color: inherit;
  padding-right: 2.5ch;

  &::-ms-expand {
    display: none;
  }
}

select[multiple] {
  padding-right: .5em;
}

.select {
  position: relative;
  display: inline-block;
  @include formField;
  line-height: normal;
  padding: 0;
  border: 0;

  &:not(.multiple)::after {
    content: '\25be';
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    right: 7px;
    top: 0;
    color: $color-grey-70;
    pointer-events: none;
    font-size: 1.1rem;
  }
}
