@import "../core.scss";

.context-message {
  @include contextMessage;

  &.error {
    @include contextMessageState("error");
  }

  &.warn {
    @include contextMessageState("warn");
  }

  &.success {
    @include contextMessageState("success");
  }
}

input:not([type]),
input[type="search"],
input[type="text"],
input[type="number"],
input[type="email"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="tel"],
input[type="time"],
input[type="password"],
input[type="url"],
input[type="file"],
textarea {
  @include formField;
}

input[type="search"] {
  border-radius: 50px;
  padding-left: $space-m;
  padding-right: $space-m;
}

input[type="file"]::-webkit-file-upload-button {
  @include overlay-button($color-blue-70);
}

/* add a red asterisk after required labels
 without having to include it in the markup */

label,
legend {
  margin: $space-stack-xs;
  display: block;

  &.required::after,
  &.optional::after,
  &.required.text::after,
  &[data-helper]::after,
  &[data-hint]::after {
    content: "*";
    color: $color-red-50;
    margin-left: $space-xs;
    font-weight: 400;
  }

  &.required.text::after {
    content: "(required)";
    color: $color-red-70;
    font-size: 0.9em;
  }

  &.optional::after {
    content: "(optional)";
    color: $color-grey-70;
    font-size: 0.9em;
  }

  &[data-helper]::after {
    content: attr(data-helper);
    color: $color-grey-70;
    font-size: 0.9em;
  }

  &[data-hint]::after {
    content: attr(data-hint);
    color: $color-grey-70;
    font-size: 0.9em;
  }
}
