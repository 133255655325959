@import "left-accent";

@mixin staggerEntry($tag: li, $delay: 0.05s, $offset: 0s) {
  @for $i from 1 to 50 {
    #{$tag}:nth-of-type(#{$i}) {
      animation-delay: $offset + $i * $delay;
    }
  }
}

@mixin listWrapper() {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin listItem() {
  position: relative;
  padding: 1em;
  border-radius: 2px;
  background-color: $color-grey-5;
  margin: $space-stack-m;
}

@mixin listError() {
  display: flex;
  align-items: center;
  color: $color-red-90;
  background: $color-red-20;
  padding-left: 1.5em;

  svg {
    height: 1.2em;
    width: 1.2em;
    fill: $color-red-90;
    margin-right: $space-sm;
  }
}
