@import "../node_modules/element.ui/modules/core";
@import "../node_modules/element.ui/modules/motion";
@import "../node_modules/element.ui/modules/buttons/craft-buttons";

.element-dialog-modal-wrapper,
.element-dialog-modal-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  top: 0;
  left: 0;
  z-index: 99999999;
}

.element-dialog-modal-backdrop {
  background: rgba($color-grey-10, 0.7);
  @include fadeIn();
}

.element-dialog-modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.element-dialog-modal {
  z-index: 999999999;
  max-width: 50%;
  box-sizing: border-box;
  padding: $space-m;
  background-color: white;
  border-radius: 4px;
  @include fadeInFromBottom();
  animation-delay: 0.5s;
  border: 1px solid $color-grey-10;
  box-shadow: 0 20px 40px -20px rgba(black, 0.7);
  position: relative;
  min-width: min-content;

  @media (max-width: 500px) {
    width: 80vw;
    max-width: 100%;
  }

  > footer button {
    @include btn;
    @include overlay-button($color-grey-90);
    font-size: 0.9em;
  }

  .close {
    height: 1.5em;
    width: 1.5em;
    padding: $space-inset-sm;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 146ms;
    fill: $color-grey-70;
    margin-left: auto;
    flex-shrink: 0;
    position: relative;
    right: -#{$space-sm};
    top: -#{$space-sm};

    &:hover {
      background: $color-grey-5;
    }
  }

  > header,
  > main,
  > footer {
    box-sizing: border-box;
  }

  > main,
  > header {
    margin: $space-stack-default;
  }

  header {
    font-weight: 500;
    display: flex;
  }

  > footer {
    display: flex;
    justify-content: flex-end;

    button + button {
      margin-left: $space-sm;
    }
  }

  > footer {
    padding-top: $space-m;
    border-top: 1px solid $color-grey-10;
  }
}
