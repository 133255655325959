@mixin leftAccent($color) {
  &::after {
    background: $color;
    content: "";
    height: 80%;
    top: 10%;
    left: -2px;
    width: 4px;
    position: absolute;
    border-radius: 2px;
  }
}
