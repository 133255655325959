@import "~@element/ui/modules/core";

%overlay {
  z-index: 1000;
  padding: 1.75rem;
  top: 0;
  right: 0;
  height: calc(100vh - 3.5rem);
  width: calc(100vw - 3.5rem);
  position: fixed;
  background-color: white;

  &.hidden {
    display: none;
  }

  button.close {
    background-color: transparent;
    height: 2rem;
    width: 2rem;
    padding: 0;
    border: none;
    align-self: flex-end;
    fill: $color-grey-70;
    svg {
      height: 2rem;
    }
    margin: $space-stack-xl;
  }
}
