@import "~@element/ui/modules/core";
@import "~@element/ui/modules/motion";

@import "../announcements/announcements";
@import "../../../shared/src/styles/profile-img";
@import "../../../shared/src/styles/messages";
@import "../../../shared/src/styles/stagger-animation";

#research-go-app {
  .extended-nav {
    .whats-new::after {
      margin-left: 0.25em;
    }
  }
  .whats-new::after {
    content: attr(data-count);
    display: flex;
    font-size: 0.8em;
    background: $color-blue-90;
    color: white;
    border-radius: 2em;
    padding: 0 0.5em;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
}

#home-view {
  color: $color-black;
  h4 {
    font-weight: 300;
    margin: 0;
  }

  &.tiled-apps-sm-text {
    .description {
      display: none;
    }
    .apps {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: $space-m;
      grid-row-gap: $space-m;

      li {
        @include shadow(2);

        border-radius: 0.3em;
        a {
          padding: 0.75em;
          display: flex;
          flex-direction: column;
          font-size: 0.9em;
          align-items: center;
          min-height: 3em;
          position: relative;

          h4 {
            font-size: 0.7em;
          }
          svg {
            height: 2.5em;
            width: 2.5em;
            fill: $color-grey-50;
            top: 0.2em;
            right: 0.2em;
            margin: $space-stack-sm;
          }
        }
      }
    }
  }

  &.tiled-apps {
    .description {
      display: none;
    }
    .apps {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: $space-m;
      grid-row-gap: $space-m;

      li {
        @include shadow();
        border-radius: 0.3em;

        a {
          padding: 0.75em;
          display: flex;
          font-size: 0.9em;
          min-height: 3em;
          position: relative;

          h4 {
            align-self: flex-end;
          }
          svg {
            height: 3.5em;
            width: 3.5em;
            order: 2;
            opacity: 0.3;
            fill: $color-blue-50;
            position: absolute;
            top: 0.2em;
            right: 0.2em;
          }
        }
      }
    }
  }

  &.card-icons {
    .icon {
      border-radius: 50%;
      padding: 0.5em;
      background-color: $color-mayo-50;
      grid-row-start: 1;
      grid-row-end: 3;
      height: 1.5em;
      width: 1.5em;
      margin-right: $space-m;
    }
    svg {
      height: 1.5em;
      width: 1.5em;
      fill: white;
    }

    .apps li {
      @include shadow();
      padding: $space-xs $space-m;
      margin: $space-stack-m;
      border-radius: 4px;

      &:last-child {
        border-bottom: none;
      }

      a {
        display: grid;
        align-items: center;
        padding: $space-m 0;
        grid-template-columns: auto 1fr;
      }

      .description {
        font-size: 0.7em;
        color: $color-grey-50;
      }

      svg {
      }
    }
  }

  &.bordered-icons {
    .icon {
      border-radius: 50%;
      padding: 0.5em;
      background-color: $color-mayo-50;
      grid-row-start: 1;
      grid-row-end: 3;
      height: 1.5em;
      width: 1.5em;
      margin-right: $space-m;
    }
    svg {
      height: 1.5em;
      width: 1.5em;
      fill: white;
    }

    .apps {
      @include staggerEntry(li, 0.05s, 0.15s);
      li {
        border-bottom: 1px solid $color-grey-10;
        @include fadeInFromLeft();
        &:last-child {
          border-bottom: none;
        }

        a {
          display: grid;
          align-items: center;
          padding: $space-m 0;
          grid-template-columns: auto 1fr;
        }

        .description {
          font-size: 0.7em;
          color: $color-grey-50;
        }

        svg {
        }
      }
    }
  }

  .user {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: $space-xl;
    margin: $space-stack-xl;
    //border-bottom: 1px solid $color-grey-10;

    > * {
      @include fadeIn();
    }

    h1 {
      display: flex;
    }

    .whats-new {
      display: flex;
      text-decoration: none;
      font-size: 0.7em;
      position: relative;
      border: 1px solid $color-blue-90;
      background: $color-blue-90;
      padding: 0.25em 0.5em;
      border-radius: 2em;

      &[data-count="0"] {
        color: $color-grey-10;
      }

      svg {
        fill: white;
        height: 1em;
        width: 1em;
        margin-right: 0.5em;
      }
    }
    &::after {
      content: " ";
      position: absolute;
      top: 100%;
      left: 1.75em;
      height: 1px;
      width: calc(100% - 3.5em);
      background-color: $color-grey-10;
    }

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-row-gap: $space-sm;
      align-items: center;
    }

    h3 {
      margin: 0;
      align-self: end;
      color: $color-mayo-50;
    }

    > div {
      font-size: 0.9em;
      color: $color-grey-70;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        color: $color-blue-90;
      }
    }

    img {
      @extend %clippy-img;
      grid-column-start: 2;
      grid-row-start: 1;
      grid-row-end: 3;
      align-items: center;
    }
  }
}

#home-view .apps {
  margin-top: auto;
  padding: 0;
  font-size: 1.2em;

  > li {
    list-style: none;
    &.disabled {
      .icon {
        background-color: $color-grey-20;
      }
    }

    a {
      color: $color-black;
      text-decoration: none;
    }
  }
}

#research-go-announcements {
  > header {
    margin: $space-stack-m;
    > h1 {
      margin: $space-stack-sm;
    }
    > button {
      font-size: 0.9em;
    }
  }
  ul {
    @include staggerEntry(li, 0.05s, 0.15s);
    @include announcement-list;
  }
}
