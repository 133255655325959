input[type=range] {
  -webkit-appearance: none;
  margin: -2.5px 0;
  min-width: 200px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 34, 0, 0.1), 0 0 0 rgba(0, 60, 0, 0.1);
  background: #e2e2e2;
  border-radius: 1.5px;
  border: 1px solid #c5c4c5;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0 0 0 #008cff, 0 0 0 #1a97ff;
  border: 1.1px solid #0070cc;
  height: 16px;
  width: 24px;
  border-radius: 12px;
  background: #008cff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #efefef;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 34, 0, 0.1), 0 0 0 rgba(0, 60, 0, 0.1);
  background: #e2e2e2;
  border-radius: 1.5px;
  border: 1px solid #c5c4c5;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0 0 0 #008cff, 0 0 0 #1a97ff;
  border: 1.1px solid #0070cc;
  height: 16px;
  width: 24px;
  border-radius: 12px;
  background: #008cff;
  cursor: pointer;
  margin-top: -6px;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #d5d5d5;
  border: 1px solid #c5c4c5;
  border-radius: 3px;
  box-shadow: 0 0 0 rgba(0, 34, 0, 0.1), 0 0 0 rgba(0, 60, 0, 0.1);
}

input[type=range]::-ms-fill-upper {
  background: #e2e2e2;
  border: 1px solid #c5c4c5;
  border-radius: 3px;
  box-shadow: 0 0 0 rgba(0, 34, 0, 0.1), 0 0 0 rgba(0, 60, 0, 0.1);
}

input[type=range]::-ms-thumb {
  box-shadow: 0 0 0 #008cff, 0 0 0 #1a97ff;
  border: 1.1px solid #0070cc;
  width: 24px;
  border-radius: 12px;
  background: #008cff;
  cursor: pointer;
  height: 16px;
}
input[type=range]:focus::-ms-fill-lower {
  background: #e2e2e2;
}
input[type=range]:focus::-ms-fill-upper {
  background: #efefef;
}
