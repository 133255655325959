@import "~@element/ui/modules/normalize";
@import "~@element/ui/modules/core";
@import "~@element/ui/modules/motion";
@import "~@element/ui/modules/buttons";
@import "~@element/ui/modules/forms";
@import "../styles/profile-img";
@import "../styles/bouncy-loader";
@import "../styles/messages";
@import "../styles/undos";
@import "../styles/user-cards";
@import "../styles/overlay";
@import "../styles/stagger-animation";

.global-header {
  max-width: 780px;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  max-height: 3em;
  transition: margin-bottom 0.3s, max-height 0.3s;
  @include shadow(1);
  margin-bottom: $space-sm;

  a {
    padding: 1em 1.75rem;
    height: 3em;
    box-sizing: border-box;
  }

  label div {
    transition: top 0.3s, right 0.3s;
    svg {
      height: 1em;
      width: 1em;
      fill: $color-black;

      transition: background 0.3s, transform 0.3s;
      transform: rotate(180deg);
    }
  }

  input {
    display: none;
  }

  a {
    display: flex;
    align-items: center;
    color: $color-blue-90;
    svg {
      height: 1.5em;
      width: 1.5em;
      margin-right: 0.5em;
      border: 1px solid white;
    }
  }

  label div {
    border-radius: 50%;
    height: 1.75em;
    width: 1.75em;
    box-sizing: border-box;
    position: absolute;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0.625em;
    // right: 1.5em;
    right: 0;
  }

  &.hidden {
    max-height: 0;
    margin: 0;
    label div {
      top: 0;
      right: 0;
      svg {
        transform: rotate(0);
        fill: $color-black;
      }
    }
  }
}

.info-msg {
  padding: 2em;
  background-color: $color-grey-5;
  color: $color-black;
  svg {
    height: 1em;
    width: 1em;
  }
}

.app-nav {
  margin-top: auto;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100px;
  box-sizing: border-box;
  background-color: white;

  &::after {
    content: " ";
    height: 1px;
    position: absolute;
    width: calc(100% - 3.5rem);
    left: 1.75rem;
    top: 0;
    background-color: $color-grey-10;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    > li {
      display: flex;

      a,
      button {
        justify-content: space-around;
      }
    }
  }

  li a,
  button {
    font-weight: 300;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    margin: 0.75em 0;
  }

  li:first-child {
    a,
    button {
      margin-left: 0.75em;
    }
  }
  li:last-child {
    a,
    button {
      margin-right: 0.75em;
    }
  }

  li.active {
    color: $color-blue-70;

    svg {
      fill: $color-blue-50;
    }
  }

  li.alert {
    position: relative;

    &::before {
      padding: 0.2em;
      font-size: 0.8em;
      box-sizing: border-box;
      height: 1rem;
      width: 1rem;
      background-color: $color-orange-50;
      border-radius: 50%;
      color: $color-white;
      position: absolute;
      content: "\00021";
      display: flex;
      align-items: center;
      justify-content: center;
      top: 1.25rem;
      right: 1.25rem;
    }
  }
}

.global-header,
.app-nav {
  button,
  svg {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.app-nav,
.extended-nav {
  font-size: 0.9em;
  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
  }

  svg {
    height: 1.8em;
    width: 1.8em;
  }
}

.extended-nav {
  @extend %overlay;
  @include fadeInFromRight();
  @include shadow(1);
  width: calc(95% - 3.5rem);
  display: flex;
  flex-direction: column;

  hr {
    margin-top: auto;
    width: 100%;
  }

  ul + button.update-required,
  ul + button.reload {
    margin-top: auto;
  }

  button.update-required {
    width: 80%;
    padding: 1em;
    box-sizing: border-box;
    align-self: center;
  }

  button.mcui-btn + hr {
    margin-top: $space-xl;
  }

  ul {
    list-style: none;
    padding: 0;
    font-size: 2rem;

    @include staggerEntry(li, 0.05s, 0.15s);
  }

  li {
    margin: $space-stack-m;
    @include fadeInFromRight();

    a {
      cursor: pointer;
      span {
        display: flex;
        align-items: center;
      }
      svg {
        height: 0.8em;
        width: 0.8em;
        margin-right: $space-l;
      }
    }
  }

  .session {
    @extend .info-msg;
    margin: $space-l 0;

    div {
      display: flex;
      align-items: center;
      margin: $space-stack-m;
    }

    svg {
      fill: $color-orange-50;
      margin-right: $space-m;
      height: 1.5em;
      width: 1.5em;
      flex-shrink: 0;
    }

    button {
      margin-left: calc(1.5em + #{$space-m});
    }
  }
}

@include media(">780px") {
  html {
    max-width: 780px;
    width: 780px;
    margin-left: auto;
    margin-right: auto;

    .app-nav ul {
      max-width: 780px;
      width: 780px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

#install-app {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: white;

  justify-content: center;
  display: flex;
  border-top: 1px solid $color-grey-20;
  padding: 1rem;
  padding-top: 3rem;

  button {
    padding: 1rem;
  }

  button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    flex-grow: 1;
  }

  button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 1.5em;
  }
}

body {
  flex-grow: 1;
  height: 100vh;
  height: -webkit-fill-available;
  position: relative;
  font-family: Roboto, sans-serif;
  margin: 0;
  font-weight: 300;
  flex-direction: column;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;

  > .view-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

html {
  min-height: 100vh;
  height: 100vh;
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
}

h1 {
  font-size: 1.5em;
}

#user-profile {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  align-self: center;
  padding-top: 2em;

  div {
    > div:first-child {
      font-size: 1.2em;
    }
    > div:last-child {
      color: $color-grey-70;
    }
  }

  img {
    @extend %clippy-img;
  }
}

#main-view {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  overflow-x: hidden;
  background: white;

  > div > *:not(header) {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    flex-grow: 1;
  }

  > div > header {
    padding: 1.75rem;
    position: relative;
  }

  > * {
    flex-grow: 1;
    flex-shrink: 0;
  }
}
