@import "core.scss";

////
/// @group buttons
////

.mcui-btn {
  @include btn();
  @include outlined-button($color-blue-70);

  &.primary {
    @include primary-button($color-blue-70);
  }

  &.pill {
    @include pill;
  }

  &.minimalist {
    @include minimalist-button($color-blue-70);
    &::before {
      left: 0;
    }

    &.center-out::after {
      right: 0;
    }

    &.center-out::before {
      left: 50%;
    }

    &.rtl::after {
      left: auto;
      right: 0;
    }

    &.rtl::before {
      left: 100%;
    }

    &.ltr {
      // default behavior
    }
  }

  &.overlay {
    @include overlay-button($color-blue-70);

    &.pill {
      @include pill;
    }
  }

  &.stateful {
    @include stateful-button($color-blue-70);
  }
}

.mcui-btn-group {
  display: flex;

  > .mcui-btn.stateful {
    @include stateful-button($color-blue-70);
  }
}
