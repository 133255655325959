@import "../core/tokens";
@import "../core/space";

input[type="radio"] {
  border-radius: 50%;
}

input[type="checkbox"] {
  border-radius: 2px;
}

$checked: inset 0 0 0 2px $color-white;

input[type="checkbox"],
input[type="radio"] {
  appearance: none;
  border: 1px solid $color-blue-70;
  padding: $size-sm;
  transition: all 246ms ease;
  background: rgba($color-white, 0.8);

  &::-ms-check {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 8 */
    filter: alpha(opacity=0); /* IE 5-7 */
    border: 1px solid $color-blue-70;
    background: rgba($color-white, 0.8);
    color: $color-blue-70;
    height: calc(1em + 2px);
    width: calc(1em + 2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:enabled:hover {
    cursor: pointer;
  }

  &:enabled:focus {
    box-shadow: 0 0 1px 1px $color-blue-70;
    outline: none;
  }

  &:enabled:focus:checked {
    box-shadow: #{$checked}, 0 0 1px 1px $color-blue-70;
  }

  &:checked {
    box-shadow: $checked;
    background-color: $color-blue-70;
  }
}

.radio-option,
.checkbox-option {
  display: flex;
  align-items: center;
  padding-right: $space-m;
  box-sizing: border-box;
  max-width: min-content;
  white-space: nowrap;
  line-height: 1.1em;

  & + & {
    margin-top: 0.35em;
  }

  > input {
    margin-right: 0.35em;
  }
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

$main: $color-blue-70;
$grey: #8d9aa9;
$bezier: cubic-bezier(0.65, 0.25, 0.56, 0.96);

.mcui-checkbox,
.mcui-radio {
  display: flex;
  align-items: center;
  user-select: none;
  padding: $size-xs 0;
  box-sizing: border-box;
  position: relative;
  margin-right: $size-l;
  margin-bottom: 0;

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  input[type="checkbox"] + div,
  input[type="radio"] + div {
    border: 1px solid $grey;
    background: rgba($color-white, 0.8);
    height: 1.4em;
    width: 1.4em;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
  }

  input[type="checkbox"] ~ div:last-child,
  input[type="radio"] ~ div:last-child {
    padding-left: $size-sm;
  }

  input[type="radio"] + div {
    border-radius: 50%;

    svg {
      fill: $main;
      transform: scale(0.8);
      transform-origin: 50% 50%;
      opacity: 0;
    }
  }

  input[type="checkbox"]:focus + div,
  input[type="radio"]:focus + div {
    outline: 2px dashed $grey;
    outline-offset: 2px;
  }

  input[type="checkbox"]:checked + div {
    border-color: $main;
    transition: border-color 146ms $bezier;

    .mcui-check {
      opacity: 1;
      transition: opacity 50ms $bezier;

      polyline {
        animation: dash-check 146ms $bezier forwards;
      }
    }
  }

  input[type="radio"]:checked + div {
    border-color: $main;

    svg {
      transition: all 146ms $bezier;
      opacity: 1;
      transform: scale(1);
      transform-origin: 50% 50%;
    }
  }

  input[type="checkbox"]:indeterminate + div::after {
    content: "";
    height: 4px;
    width: 60%;
    left: 20%;
    top: calc(50% - 2px);
    position: absolute;
    background: $grey;
    border-radius: 4px;
  }

  input[type="checkbox"]:disabled ~ div,
  input[type="radio"]:disabled ~ div {
    color: $grey;
    cursor: not-allowed;
  }

  input[type="checkbox"]:enabled ~ div,
  input[type="radio"]:enabled ~ div {
    cursor: default;
  }
}

.mcui-check {
  height: 100%;
  width: 100%;
  transform: scale(1);
  color: $main;
  opacity: 0;

  polyline {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform-origin: 50% 50%;
    stroke-width: 5px;
    stroke-dasharray: 22.771367900227325;
    stroke: currentcolor;
  }
}

@supports (display: grid) {
  .mcui-check {
    polyline {
      stroke-dashoffset: 22.771367900227325;
    }
  }
}

@keyframes dash-check {
  to {
    stroke-dashoffset: 0;
  }
}
