@import "../../node_modules/@element/ui/modules/core";
@import "../../node_modules/@element/ui/modules/motion";
@import "../../node_modules/@element/ui/modules/forms";

.provide-feedback {
  display: flex;
  flex-direction: column;

  textarea {
    margin-top: $space-l;
    min-height: 20vh;
    width: 100%;
  }

  form button[type="submit"] {
    margin-top: 1.7em;
  }

  section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .irb-success-message,
    .irb-info-message,
    .irb-error {
      margin-top: $space-l;
      @include fadeIn();
    }

    .irb-info-message {
      animation-delay: 300ms;
    }

    form .error-msg {
      display: flex;
      align-items: center;
      color: $color-red-90;
      font-size: 0.9em;
      height: 1.3em;
      margin: $space-stack-sm;

      svg {
        height: 1.4rem;
        width: 1.4rem;
        fill: $color-red-70;
        margin-right: $space-sm;
      }
    }

    .irb-info-message.contact {
      animation: none;
      opacity: 1;
      margin: auto 0 $space-l;
      font-size: 0.8em;

      a {
        color: inherit;
      }
    }
  }
}
